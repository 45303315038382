export function queryString(data) {
  var out = [];
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      out.push(key + '=' + encodeURIComponent(data[key]));
    }
  }
  let returnValue = out.join('&');
  return '?'+returnValue;
}

export function formatPhone(phone) {
  phone = phone.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
  }
  return phone;
}

export function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = (typeof el === 'string') ? document.querySelector(el) : el; 

  var styles = window.getComputedStyle(el);
  var margin = parseFloat(styles['marginTop']) +
               parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
}

export function matchParents(el) {
	if (window.innerWidth > 992) {
    console.log(window);
    console.log(el.querySelectorAll(".match-parent"));
		el.querySelectorAll(".match-parent").forEach((matchParent) => {
			let highestHeight = 0;
			matchParent.querySelectorAll(".match").forEach((match) => {
        if (getAbsoluteHeight(match) > highestHeight) { highestHeight = getAbsoluteHeight(match) }
			});

			matchParent.querySelectorAll(".match").forEach((match) => {
				match.style.height = highestHeight;
			});
		});
	}
}

export function validatePositiveQtys() {
  document.querySelectorAll(".qty").forEach(qty => {
    qty.onchange = () => {
      let isInt = /^\+?(0|[1-9]\d*)$/.test(qty.value);
      if (!isInt) {
        qty.value = 1;
      }
    };
  });
}