<template>
  <div class="address-item">
    <h5>{{ address.name }} <span v-if="address.company != null">({{ address.company }})</span></h5>
    <div class="address text-1">
      {{ address.address1 }}
      {{ address.city }}, {{ address.state }} {{ address.zipcode }}
      {{ address.country }}
    </div>
    <div class="phone text-1" v-if="address.phone != null">
      Phone: {{ address.phone }}
    </div>
    <div class="actions">
      <a class="text-0" @click="editAddress(address)">Edit</a> | <a class="text-0" @click="deleteAddress(address.id)">Delete</a>
    </div>
    <div class="popup edit-address" v-if="editingAddress != null">
      <a class="close" @click="close"><i class="far fa-times"></i></a>
      <h4>Edit Your Address</h4>
      <form class="form" ref="editAddressForm" v-on:submit.prevent="updateAddress">
        <!-- <div class="form-item">
          <select v-model="editingAddress.country" required>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
        </div> -->
        <div class="form-item half">
          <input type="text" placeholder="Name" v-model="editingAddress.name" required />
        </div>
        <div class="form-item half">
          <input type="text" placeholder="Company" v-model="editingAddress.company" required />
        </div>
        <div class="form-item">
          <input type="text" placeholder="Street Address" v-model="editingAddress.address1" required />
        </div>
        <div class="form-item half">
          <input type="text" placeholder="City" v-model="editingAddress.city" required />
        </div>
        <div class="form-item half">
          <select v-model="editingAddress.state" required>
            <option value="">Choose State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="GU">Guam</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="PR">Puerto Rico</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VI">Virgin Islands</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div class="form-item half">
          <input type="text" placeholder="5 Digit Zipcode (xxxxx)" v-model="editingAddress.zipcode" required :maxlength="5" />
        </div>
        <div class="form-item half">
          <input type="text" placeholder="Phone" v-model="editingAddress.phone" required />
        </div>
        <div class="form-item" v-if="editingAddress.country == 'CA' && editingAddress.type == 'shipping'">
          <label>Tax ID</label>
          <input type="text" v-model="editingAddress['tax_id']" />
        </div>
        <div class="form-item full">
          <button type="submit" class="button">Save Address</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'

export default {
  name: 'Address',
  props: [
    'address'
  ],
  data() {
    return {
      editingAddress: null
    };
  },
  methods: {
    editAddress(address) {
      this.editingAddress = address;
    },
    deleteAddress(addressId) {
      this.$confirm({
        message: `Are you sure you want to delete this address?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            Api.deleteAuth('account/address/'+addressId, this.$store.state.userData.token).then(response => {
              console.log(response);
              this.$emit('address-updated');
            });
          }
        }
      });
    },
    updateAddress() {
      // Make sure form is validate before proceeding
			if (!this.$refs.editAddressForm.checkValidity()) {
				this.$refs.editAddressForm.reportValidity();
        return false;
      }
      this.$overlay(true, 'Saving address changes.');
      Api.putAuth('account/address/'+this.editingAddress.id, this.editingAddress, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$emit('address-updated');
          this.$overlay(false);
          this.close();
        }
      });
    },
    close() {
      this.editingAddress = null;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>