<template>
  <div class="reschedule popup">
    <a class="close" @click="close"><i class="far fa-times"></i></a>
    <form class="form" ref="form" v-on:submit.prevent="reserveAppointment">
      <div class="table-row flex-column w-100 full-test mb-3">
        <h3 class="text-center">Swap Appointment</h3>

        <div class="form-item text-center mb-3">
          <p class="mb-0"><strong>Currently Scheduled For</strong></p>
          <p>{{ formattedDatetime }} at {{ appointment.location.name }}</p>
        </div>

        <div class="form-item text-center py-2">
          <label>Choose Another Scheduled Appointment</label>
          <v-select v-model="otherAppointment" :options="appointmentOptions" required></v-select>
        </div>

        <div class="form-item text-center d-flex flex-column">
          <input type="submit" class="button" value="Swap Appointments" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import moment from 'moment/moment.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Swap',
  props: [
    'appointment',
    'appointments'
  ],
  components: {
    vSelect
  },
  computed: {
    formattedDatetime() {
      return moment(this.appointment.datetime).format("MMM Do YYYY h:mm:ssa");
    },
    appointmentOptions() {
      var appointmentOptions = [];
      if (this.appointments != null) {
        for (const key of Object.keys(this.appointments)) {
          if (this.appointments[key].id != this.appointment.id) {
            appointmentOptions.push({
              label: "("+this.appointments[key].user.first_name + ' ' + this.appointments[key].user.last_name+') '+this.appointments[key].datetime+' at '+this.appointments[key].location.name,
              code: this.appointments[key].id
            });
          }
        }
      }
      return appointmentOptions;
    }
  },
  data() {
    return {
      otherAppointment: null
    };
  },
  methods: {
    reserveAppointment() {
      if (!this.$refs.form.checkValidity()) {
				this.$refs.form.reportValidity();
        return false;
      }
      this.$overlay(true, 'Rescheduling your appointment.');
      let data = { oldAppointmentId: this.appointment.id, otherAppointmentId: this.otherAppointment.code };
      Api.putAuth('schedule/swap-appointments', data, this.$store.state.userData.token).then(response => {
        console.log(response);
        if (!response == false) {
          this.$overlay(true, 'There was an error swapping the appointments. Please contact us to finish.', 3000);
        }
        
        this.submitting = false;
        this.$overlay(true, 'The appointments were successfully swapped.', 3000);
        this.$emit('close');
      });
      return false;
    },
    close() {
      this.$emit('close');
    },
    setFields() {
      this.location = this.appointment.location.id;
      this.classType = this.appointment.classType;
      this.licenseNumber = this.appointment.licenseNumber;
    }
  },
  mounted() {
    this.setFields();
  }
}
</script>

<style lang="scss" scoped>

</style>