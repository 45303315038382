<template>
  <div class="address-line">
    <div class="select">
      <input type="radio" name="address" class="mr-2" v-model="$store.state.orderInfo[address.type+'Address']" :value="address.id" @click="$emit('address-selected', address.id)" />
    </div>
    <div class="label">
      <h5 class="mb-0">{{ address.name }} <span v-if="address.company != null">({{ address.company }})</span></h5>
      <div class="address text-1">
        {{ address.address1 }}
        {{ address.city }}, {{ address.state }} {{ address.zipcode }}
        {{ address.country }}
        <div v-if="address.tax_id != null">Tax ID: {{ address.tax_id }}</div>
        <div v-if="address.customs_broker_name != null && address.customs_broker_phone != null">Customs Broker: {{ address.customs_broker_name }} ({{ address.customs_broker_phone }})</div>
        <div v-if="address.ocean_booking_number != null && address.ocean_booking_number != null">Ocean Booking Number: {{ address.ocean_booking_number }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressLine',
  props: [
    'address'
  ],
  data() {
    return {
      
    };
  }
}
</script>

<style lang="scss" scoped>

</style>