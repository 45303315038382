<template>
  <div id="review-order">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6">
            <section id="address-review" class="box-shadow red-links mb-3" v-if="typeof $store.state.orderInfo.billingAddress != 'undefined' && $store.state.orderInfo.billingAddress != null">
              <div class="content p-4 white-bg">
                <div class="row">
                  <div class="col-6">
                    <h5>Billing Address</h5>
                    <p>{{ $store.getters.orderBillingAddress.company }}<br>
                    {{ $store.getters.orderBillingAddress.name }}<br>
                    {{ $store.getters.orderBillingAddress.address1 }}<br>
                    {{ $store.getters.orderBillingAddress.city }}, {{ $store.getters.orderBillingAddress.state }} {{ $store.getters.orderBillingAddress.zipcode }}
                    </p>
                    <a @click="$store.commit('changeOrderStep', 'billing')">Edit</a>
                  </div>
                </div>
              </div>
            </section>
            <section id="shipping-review" class="box-shadow red-links mb-3">
              <div class="box-header mb-0">Payment Method</div>
              <div class="content p-4 white-bg">
                <h5>{{ $store.state.orderInfo.paymentMethod }}</h5>
                <p v-if="$store.state.orderInfo.ccInfo.number != ''">XXXXXXXXXXXX{{ $store.state.orderInfo.ccInfo.number.substr($store.state.orderInfo.ccInfo.number.length - 4) }}</p>
                <a class="d-block" @click="$store.commit('changeOrderStep', 'billing')">Edit</a>
              </div>
            </section>
          </div>
          <div class="col-12 col-lg-6">
            <Cart></Cart>
            <section id="place-order" class="white-bg p-3 box-shadow">
              <div class="row d-flex align-items-center">
                <div class="col-6">
                  <a class="checkout button red-bg w-100" @click="submitOrder" v-if="$store.getters.isOrderComplete">Submit Order</a>
                </div>
                <div class="col-6 text-0">
                  Your order will process and you will be charged. Finalize your order before proceeding. Do not navigate away from this page until your submission is complete.
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Cart from '@/components/Cart.vue'
import Api from '@/models/Api.js'
import GlobalMethods from '@/components/GlobalMethods.js'
import qs from 'qs'

export default {
  name: 'ReviewOrder',
  components: {
    Cart
  },
  data() {
    return {
      paymentComplete: false,
      submitting: false,
      stripeToken: null
    };
  },
  computed: {
    poNumber: {
      get() {
        return this.$store.state.orderInfo.poNumber;
      },
      set(value) {
        this.$store.commit('setPoNumber', value);
      }
    }
  },
  methods: {
    ...GlobalMethods,
    submitOrder() {
      if (this.submitting == true) {
        return false;
      }
      this.$overlay(true, 'Wait one moment while we process your order. Do not navigate away from this page until your submission is complete or your order may not be correctly processed.</div>');

      this.logOrder();

      if (this.$store.state.orderInfo.paymentMethod == 'Credit Card') {
        this.pay();
      } else {
        this.saveOrder();
      }
    },
    saveOrder(paymentResponse=null) {
      let order = {
        customerId: this.$store.state.userData.id,
        ...this.$store.state.orderInfo,
        orderItems: this.$store.state.cartItems,
        totalPrice: this.$store.getters.cartFinalTotal,
        paymentResponse: paymentResponse,
        orderUrl: this.$store.state.orderUrl
      };
      Api.postAuth('order', order, this.$store.state.userData.token).then(response => {
        console.log(response);
        if (!response.success == false) {
          this.$overlay(true, 'There was an error saving your order. Please contact us to finish your order.', 3000);
        }
        this.$store.commit('setCompleteOrder', response.order);
        this.$store.commit('changeOrderStep', 'confirmation');
        this.$store.commit('resetOrder');
        this.$overlay(false);
        this.submitting = false;
      });
    },
    logOrder() {
      let order = {
        ...this.$store.state.orderInfo,
        ...this.$store.state.userData,
        orderItems: this.$store.state.cartItems,
        totalPrice: this.$store.getters.cartFinalTotal
      };
      Api.postAuth('log-order', order, this.$store.state.userData.token).then(response => {
        return response;
      });
    },
    getPaymentToken() {
      let tokenRequest = {
        'card': {
          'number': this.$store.state.orderInfo.ccInfo.number,
          'exp_month': this.$store.state.orderInfo.ccInfo.month,
          'exp_year': this.$store.state.orderInfo.ccInfo.year.substr(-2),
          'cvc': this.$store.state.orderInfo.ccInfo.cvv
        }
      };
      //this.axios.post('https://api.stripe.com/v1/tokens', qs.stringify(tokenRequest), { headers: { 'Authorization': 'Bearer pk_test_UOP63FRwe8AlkaJwGY8Je7xP', 'content-type': 'application/x-www-form-urlencoded' } }).then(response => {
      this.axios.post('https://api.stripe.com/v1/tokens', qs.stringify(tokenRequest), { headers: { 'Authorization': 'Bearer pk_live_51JZz1oEYJSWOQ38PLaleUR4c2QOa9cBvx38u1dZAg9AtCIHcLejKEjwfISfForxbQNG4GSrHOB1w9QvRbNSqYlVn006Eo89d0s', 'content-type': 'application/x-www-form-urlencoded' } }).then(response => {
        console.log(response);
        this.stripeToken = response.data.id;
      }).catch(() => {
        this.$overlay(true, 'The credit card information you entered was not valid. Try again.', 2500);
        this.$store.commit('changeOrderStep', 'billing');
      });
    },
    pay() {
      var paymentData = {
        amount: parseInt(this.$store.getters.cartFinalTotal * 100),
        currency: 'usd',
        source: this.stripeToken
      };
      //this.axios.post('https://api.stripe.com/v1/charges', qs.stringify(paymentData), { headers: { 'Authorization': 'Bearer sk_test_gDQ3iJvvqlM7u9TdKU0iL3rq', 'content-type': 'application/x-www-form-urlencoded' } }).then(response => {
      this.axios.post('https://api.stripe.com/v1/charges', qs.stringify(paymentData), { headers: { 'Authorization': 'Bearer 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', 'content-type': 'application/x-www-form-urlencoded' } }).then(response => {
        console.log(response);
        this.saveOrder(response.id);
      }).catch(() => {
        this.$overlay(true, 'Something went wrong processing your payment.', 2500);
        this.$store.commit('changeOrderStep', 'billing');
      });
    }
  },
  beforeMount() {
    this.checkOrderProgress();
  },
  mounted() {
    if (this.$store.state.orderInfo.paymentMethod == 'Credit Card') {
      this.getPaymentToken();
    }
  }
}
</script>

<style lang="scss" scoped>

</style>