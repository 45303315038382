<template>
  <div class="full-screen-overlay" v-if="overlay">
    <div class="message" v-html="message"></div>
  </div>
</template>

<script>
  export default {
    name: 'Overlay',
    data() {
      return {
        overlay: false,
        message: '',
        timeout: null,
        timer: null
      };
    },
    watch: {
      timeout(newVal) {
        if (newVal == null) return;
        this.timer = setTimeout(() => {
          this.close();
        }, newVal);
      }
    },
    methods: {
      close() {
        this.overlay = false;
        this.timeout = null;
        this.message = '';
        this.timer = null;
        
        clearTimeout(this.timer);
      }
    },
    mounted() {

    }
  }
</script>

<style lang="scss" scoped>

</style>