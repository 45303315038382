import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import Cookies from 'js-cookie'
import Api from '@/models/Api.js'

Vue.use(Vuex)

Vue.config.devtools = true

function isItemInCart(cartItems, item) {
  let result;
  result = cartItems.findIndex((e) => e['ID'] == item['ID'] && e.type == item.type);
  if (result != -1) {
    return result;
  } else {
    return false;
  }
}

function isDiscountApplied(discounts, item) {
  let result;
  result = discounts.findIndex((e) => e['name'] == item['name']);
  if (result != -1) {
    return true;
  } else {
    return false;
  }
}

const persistedState = createPersistedState({
  paths: ['cartItems', 'cartLastUpdated', 'userData']
})

export default new Vuex.Store({
  plugins: [persistedState],
  state: {
    cartItems: [],
    cartLastUpdated: null,
    orderInfo: {
      billingAddress: null,
      discounts: [
        // {
        //   name: 'Online Discount',
        //   sub: '',
        //   discount: '-2',
        //   type: 'percentage'
        // }
      ],
      ccInfo: {
        number: '',
        firstName: '',
        lastName: '',
        month: '',
        year: '',
        cvv: ''
      },
      paymentMethod: 'Credit Card',
      poNumber: null
    },
    orderStep: 'products',
    orderSteps: [
      'products',
      'cart',
      'billing',
      'review'
    ],
    prevStep: null,
    billingAddresses: [],
    addresses: [],
    users: [],
    orderUrl: null,
    completeOrder: null
  },
  mutations: {
    addToCart(state, item) {
      if (item.qty == 0) return;
      let cartItemIndex = isItemInCart(state.cartItems, item);
      if (cartItemIndex !== false) {
        item.qty = parseInt(item.qty) + parseInt(state.cartItems[cartItemIndex].qty);
        state.cartItems.splice(cartItemIndex, 1, item);
      } else {
        state.cartItems.push(item);
      }
      state.cartLastUpdated = Date.now();
    },
    removeFromCart(state, index) {
      state.cartItems.splice(index, 1);
      if (state.cartItems.length == 0) {
        state.orderStep = 'products';
      }
    },
    resetCart(state) {
      state.cartItems = [];
    },
    removeIncompleteCartItems(state) {
      if (state.cartItems.length > 0) {
        let completeCartItems = state.cartItems.filter(cartItem => cartItem.scheduleStatus !== false);
        state.cartItems = completeCartItems;
      }
    },
    setCartLastUpdated(state, lastUpdated) {
      state.cartLastUpdated = lastUpdated;
    },
    addDiscount(state, discount) {
      if (isDiscountApplied(state.orderInfo.discounts, discount)) {
        return false;
      }
      state.orderInfo.discounts.push(discount);
    },
    setPoNumber(state, poNumber) {
      state.orderInfo.poNumber = poNumber;
    },
    setPaymentMethod(state, paymentMethod) {
      state.orderInfo.paymentMethod = paymentMethod;
    },
    setBillingAddress(state, billingAddress) {
      state.orderInfo.billingAddress = billingAddress;
    },
    setCcInfo(state, ccInfo) {
      state.orderInfo.ccInfo = ccInfo;
    },
    setOrderInfo(state, { key, value }) {
      state.orderInfo[key] = value;
    },
    changeOrderStep(state, step) {
      state.prevStep = state.orderStep;
      if ((step == 'cart' || step == 'billing') && state.cartItems.length == 0) return;
      window.location.hash = step;
      state.orderStep = step;
      document.querySelector("#scheduler #nav").scrollIntoView({
        behavior: 'smooth'
      });
    },
    prevStep(state) {
      if (state.prevStep != null) {
        state.orderStep = state.prevStep;
      }
    },
    setUserData(state, userData) {
      Cookies.set('userData', JSON.stringify(userData), { expires: 1 });
      state.userData = userData;
    },
    logout(state) {
      Cookies.remove('userData');
      state.userData = null;
      state.orderStep = 'products';
    },
    getUserAddresses(state) {
      Api.getAuth('account/addresses?customer-id='+state.userData.id, state.userData.token).then(response => {
        console.log(response);
        state.addresses = response;
        state.billingAddresses = response.filter((el) => { return el.type == 'billing'; });
      });
    },
    getUserUsers(state) {
      Api.getAuth('account/users?customer-id='+state.userData.id, state.userData.token).then(response => {
        state.users = response;
      });
    },
    resetOrder(state) {
      state.orderInfo = {
        billingAddress: null,
        discounts: [
          // {
          //   name: 'Credit Card Discount',
          //   sub: 'Fruit Only',
          //   discount: '-2',
          //   type: 'percentage'
          // }
        ],
        ccInfo: {
          number: '',
          firstName: '',
          lastName: '',
          month: '',
          year: '',
          cvv: ''
        },
        paymentMethod: 'Credit Card',
        poNumber: null
      };
      state.cartItems = [];
    },
    setOrderUrl(state, orderUrl) {
      state.orderUrl = orderUrl;
    },
    addAppointmentToIncompleteCartItem(state, appointmentObject) {
      let incompleteCartItemIndex = state.cartItems.findIndex(cartItem => cartItem.scheduleStatus === false);
      state.cartItems[incompleteCartItemIndex]['scheduleStatus'] = true;
      state.cartItems[incompleteCartItemIndex]['scheduleAppointmentId'] = appointmentObject.appointmentId;
      state.cartItems[incompleteCartItemIndex]['scheduleAppointmentDate'] = appointmentObject.date;
      state.cartItems[incompleteCartItemIndex]['scheduleAppointmentTime'] = appointmentObject.time;
      state.cartItems[incompleteCartItemIndex]['licenseNumber'] = appointmentObject.licenseNumber;
      state.cartItems[incompleteCartItemIndex]['classType'] = appointmentObject.classType;
      state.cartItems[incompleteCartItemIndex]['user'] = appointmentObject.user;
    },
    addVoucherToIncompleteCartItem(state) {
      let incompleteCartItemIndex = state.cartItems.findIndex(cartItem => cartItem.scheduleStatus === false);
      state.cartItems[incompleteCartItemIndex]['scheduleStatus'] = true;
      state.cartItems[incompleteCartItemIndex]['scheduleAppointmentId'] = 'voucher';
    },
    setCompleteOrder(state, order) {
      state.completeOrder = order;
    }
  },
  getters: {
    cartTotal: state => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        total = parseFloat(total) + (parseFloat(cartItem.price) * cartItem.qty);
      });
      return total.toFixed(2);
    },
    cartTotalType: state => (type) => {
      let total = 0;
      state.cartItems.forEach(cartItem => {
        if (cartItem.post_type != type) return;
        total = parseFloat(total) + parseFloat(cartItem.price);
      });
      return total.toFixed(2);
    },
    cartFinalTotal: (state, getters) => {
      let total = 0;
      
      total = parseFloat(total) + parseFloat(getters.cartTotal);

      state.orderInfo.discounts.forEach(discount => {
        if (discount.type == 'percentage') {
          total = parseFloat(total) + (parseFloat(total) * parseFloat(discount.discount*.01));
        }
        if (discount.type == 'fixed') {
          console.log(parseFloat(discount.discount).toFixed(2));
          total = parseFloat(total) - parseFloat(discount.discount).toFixed(2);
        }
      });

      if (total < 0) {
        total = 0.00;
      }

      return parseFloat(total).toFixed(2);
    },
    addressById: (state) => (id) => {
      return state.addresses.filter((el) => { return el.id == id; })[0];
    },
    orderBillingAddress: (state) => {
      return state.addresses.filter((el) => { return el.id == state.orderInfo.billingAddress; })[0];
    },
    isBillingAddressComplete: (state) => {
      return state.orderInfo.billingAddress != null;
    },
    isCcInfoComplete: (state) => {
      return state.orderInfo.ccInfo.number != "" && state.orderInfo.ccInfo.month != "" && state.orderInfo.ccInfo.year != "" && state.orderInfo.ccInfo.cvv != "";
    },
    isOrderComplete: (state, getters) => {
      return (getters.isBillingAddressComplete && getters.isCcInfoComplete) || (state.orderInfo.paymentMethod == 'Free');
    },
    discountAmount: (state, getters) => (discount) => {
      if (discount.type == 'percentage') {
        return parseFloat(getters.cartTotalType('puree') * (discount.discount*.01)).toFixed(2);
      } else if (discount.type == 'fixed') {
        return parseFloat(discount.discount).toFixed(2);
      } else {
        return '0.00';
      }
    }
  }
})
