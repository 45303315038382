<template>
  <div id="cart" class="cart">
    <div class="contents" v-if="$store.state.cartItems.length > 0">
      <h4 class="text-center text-uppercase black">Shopping Cart</h4>
      <div class="cart-items">
        <div class="cart-item" v-for="(cartItem, index) in $store.state.cartItems" v-bind:key="cartItem['ID']">
          <div class="description">
            <h5 class="mb-1">{{ cartItem.post_title }}</h5>
            <div class="text-0 mb-1" v-if="cartItem.notes != null && !cartItem.notes.includes('undefined')">{{ cartItem.notes }}</div>
            <div class="text-0 mb-1" v-if="cartItem.scheduleAppointmentId != null && cartItem.scheduleAppointmentId != 'voucher'">{{ cartItem.scheduleAppointmentDate }} ({{ cartItem.scheduleAppointmentTime }})</div>
            <div class="text-0 mb-1" v-if="cartItem.scheduleAppointmentId == 'voucher'">Voucher Generated Upon Checkout</div>
            <div class="text-0 mb-1" v-if="cartItem.vehicle != null">{{ cartItem.vehicle }}</div>
            <div class="sub d-flex align-items-center">
              <input type="number" class="mr-1 text-center qty" min="1" v-model="cartItem.qty" style="width: 46px;" v-if="($store.state.orderStep == 'products' || $store.state.orderStep == 'cart') && cartItem.vehicle == null && cartItem.scheduleAppointmentId == 'voucher'" />
              <span class="mr-2" v-else>
                <span class="font-weight-bold">QTY: </span>
                {{ cartItem.qty }}
              </span>
              <span class="price">${{ (cartItem.price) ? cartItem.price : '0.00' }}</span> / <span class="boxes">{{ cartItem.type }}</span>
            </div>
          </div>
          <a class="remove" @click="removeFromCart(index)" v-if="$store.state.orderStep == 'products' || $store.state.orderStep == 'cart' || $store.state.orderStep == 'billing'"><i class="far fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="contents" v-else>
      <h4 class="text-center text-uppercase black">Shopping Cart</h4>
      <div class="cart-item text-center d-flex justify-content-center">
        Your cart is empty.
      </div>
    </div>
    <a class="checkout button red-bg w-100 d-block" @click="$store.commit('changeOrderStep', 'cart')" v-if="$store.state.orderStep == 'products' && $store.state.cartItems.length > 0">Checkout</a>
    <a class="checkout button red-bg w-100 d-block" @click="$store.commit('changeOrderStep', 'account')" v-if="$store.state.orderStep == 'cart'">Continue Checkout</a>
    <a class="checkout button red-bg w-100 d-block" @click="$store.commit('changeOrderStep', 'review')" v-if="$store.state.orderStep == 'billing' && $store.getters.isOrderComplete && financingAllowed">Review Order</a>
    <div class="total-bar text-0 gray-bg-2" v-for="discount in $store.state.orderInfo.discounts" v-bind:key="discount.name">
      <label>
        <div>{{ discount.name }}</div>
        <div class="text-0 font-weight-normal" v-if="discount.sub != ''">{{ discount.sub }}</div>  
      </label>
      <div class="total">{{ formatDiscount(discount) }} ${{ $store.getters.discountAmount(discount) }}</div>
    </div>
    <div class="total-bar align-items-center">
      <label class="text-1">Total Price</label>
      <div class="total">${{ $store.getters.cartFinalTotal }}</div>
    </div>
  </div>
</template>

<script>
import { validatePositiveQtys } from '@/helpers.js'

export default {
  name: 'Cart',
  data() {
    return {
      cart: [],
      paymentToken: null
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    financingAllowed() {
      if (this.$store.state.orderInfo.paymentMethod == 'Invoice' && this.$store.state.userData.financing != true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    removeFromCart(index) {
      this.$store.commit('removeFromCart', index);
    },
    formatDiscount(discount) {
      if (discount.type == 'percentage') {
        return discount.discount + '%';
      }
    }
  },
  mounted() {
    validatePositiveQtys();
  }
}
</script>

<style lang="scss">

</style>