<template>
  <div id="products">
    <div class="product-table-no-vue">
      <div class="tabs">
        <a class="black" v-bind:class="{ 'selected': currentTab == 'tests' }" @click="setCurrentTab('tests')">Choose Your Test</a>
      </div>
      <div class="tab-panels">
        <div class="tab-panel" v-if="currentTab == 'tests'">
          <div class="table-header w-100">
            <div class="col product"></div>
            <div class="col add d-flex justify-content-end"></div>
          </div>
          <div class="tab-panels">
            <div class="table-row py-4 px-3 flex-column w-100" v-bind:class="product.post_name" v-for="product in products" v-bind:key="product.post_title" :ref="'product-'+product.post_title">
              <div class="top w-100">
                <div class="col product font-weight-bold cursor-pointer py-0" @click="setPopup(product['ID'])"><h4 class="font-weight-bold primary mb-0">{{ product.post_title }}</h4></div>
                <div class="popup scheduler-popup" v-if="popupId == product['ID']">
                  <a class="close" @click="setPopup(null)"><i class="far fa-times"></i></a>
                  <div class="content">
                    <h4>{{ product.post_title }}</h4>
                    <div class="description" v-html="product.description"></div>
                  </div>
                </div>
                <div class="col d-flex justify-content-end"></div>
              </div>
              <div class="bottom">
                <div class="col description mr-0 mr-lg-4">
                  <h5>Description</h5>
                  <div class="content text-1" v-html="product.description">
                  </div>
                </div>
                <div class="col options" v-if="product.out_of_stock != true">
                  <div class="w-100 d-flex flex-column align-items-center">
                    <div class="font-weight-bold text-center mb-2">${{ product['price'] }}</div>
                    <a class="button add-to-cart red-bg white mb-3 w-100" @click="addToCart(product)">Purchase Test &amp; Schedule</a>
                    <a style="cursor: pointer;" @click="addVoucherToCart(product)">Purchase Voucher (Businesses Only)</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import { queryString } from '@/helpers.js'
import { validatePositiveQtys } from '@/helpers.js'

export default {
  name: 'Products',
  data() {
    return {
      products: null,
      currentTab: 'tests',
      popupId: null,
      priceValues: {},
      totalValues: null,
      expandedProduct: null
    };
  },
  methods: {
    getProducts(queryString) {
      let url = 'tests';
      if (queryString) {
        url += queryString;
      }
      Api.get(url).then(response => {
        this.products = response;
      });
    },
    getFilteredProducts() {
      this.getProducts(queryString({ type: (this.currentTab ? this.currentTab : '') }));
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    setPopup(id) {
      this.popupId = id;
    },
    resetInputs() {
      this.addQty = {};
    },
    addToCart(product) {
      let cartItem = { ...product, scheduleStatus: false, qty: 1 };
      this.$store.commit('addToCart', cartItem);
      this.$store.commit('changeOrderStep', 'entry-level-drivers-training');
      this.resetInputs();
    },
    addVoucherToCart(product) {
      let cartItem = { ...product, scheduleStatus: false, qty: 1 };
      this.$store.commit('addToCart', cartItem);
      this.$store.commit('addVoucherToIncompleteCartItem');
      this.$store.commit('changeOrderStep', 'cart');
    },
    toggleProduct(id) {
      if (this.expandedProduct == id) {
        this.expandedProduct = null;
      } else {
        this.expandedProduct = id;
        this.addQty = {};
      }
    },
    getProductPricePer(product) {
      if (product['ID'] in this.typeValues && typeof product.prices[this.typeValues[product['ID']]] != 'undefined') {
        return '$'+product.prices[this.typeValues[product['ID']]] + ' / ' + this.typeValues[product['ID']];
      } else {
        return '';
      }
    },
    clearCartIfExpired() {
      if (Date.now() > parseInt(this.$store.state.cartLastUpdated) + 43200000) {
        this.$store.commit('resetCart');
      } else {
        console.log((((parseInt(this.$store.state.cartLastUpdated) + 43200000) - Date.now()) / 1000) + ' seconds until cart expires');
      }
    }
  },
  mounted() {
    this.$store.commit('removeIncompleteCartItems');
  },
  created() {
    this.getFilteredProducts();
    this.qtyValues = {};
    this.typeValues = {};
    this.totalValues = {};
    this.addQty = {};
    validatePositiveQtys();

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    let product = params.get("product");
    if (product) {
      this.setPopup(product);
    }
    this.clearCartIfExpired();
  }
}
</script>

<style lang="scss">

</style>