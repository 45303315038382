<template>
  <div id="schedule">
    <form class="form" ref="form">
      <div class="table-row py-4 px-3 flex-column w-100 full-test mb-3">
        <h3 class="text-center text-uppercase mb-3">Schedule Appointment</h3>

        <div class="form-item text-center py-2" v-if="$store.state.userData.role == 'organization' && $store.state.users.length > 0">
          <label>Who Will Take The Test?</label>
          <v-select v-model="user" :options="userOptions" required>
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="true"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
        </div>

        <div class="form-item text-center py-2">
          <label>Choose Your Testing Location</label>
          <select v-model="location" required>
            <option :value="location['ID']" v-for="location in locations" :key="location">{{ location.post_title }}</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="location != null && dates != null && dates != false">
          <label>Choose A Day For Your Test</label>
          <div class="d-block">
            <v-date-picker
            v-model="date"
            mode='date'
            :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            }"
            :available-dates="dates"
            />
          </div>
        </div>

        <div class="form-item text-center py-2" v-if="dates == false">
          <p class="font-italic text-center">Sorry, we have no available appointment dates for this location. Please check back soon.</p>
        </div>

        <div class="form-item text-center py-2" v-if="date != null">
          <label>Choose The Time For Your Test</label>
          <select v-model="time" required>
            <option :value="key" v-for="(time, key) in times" :key="time">{{ convertTime(time) }}</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="time != null">
          <label>Class A or Class B</label>
          <select v-model="classType" required>
            <option value="">Select an option</option>
            <option value="Class A">Class A</option>
            <option value="Class B">Class B</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="time != null">
          <label>License/Permit #</label>
          <input type="text" v-model="licenseNumber" required min="5" />
        </div>

        <div class="form-item text-center py-2 d-flex justify-content-center align-items-center" v-if="time != null">
          <label class="m-0" style="font-weight: 500; font-size: 1rem; margin-right: 1rem !important; text-transform: capitalize;">I Understand That I Can Only Reschedule Until 48 Hours Before The Date Of My Test. <a href="/wp-content/uploads/2021/11/Testing-Registration-Disclaimer11_07_21.pdf" target="_blank">Click here to read out terms and conditions.</a></label>
          <input type="checkbox" v-model="rescheduleNotice" />
        </div>

        <div class="form-item text-center d-flex flex-column" v-if="time != null && licenseNumber != null && classType != null && rescheduleNotice == true">
          <button class="button" @click="reserveAppointment">Reserve Appointment</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import moment from 'moment/moment.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Schedule',
  components: {
    vSelect
  },
  data() {
    return {
      location: null,
      date: null,
      time: null,
      locations: null,
      dates: null,
      times: null,
      licenseNumber: null,
      classType: null,
      rescheduleNotice: false,
      user: null
    };
  },
  watch: {
    location() {
      this.getDates();
      this.date = null;
      this.time = null;
    },
    date() {
      this.getTimes();
      this.time = null;
    },
    user() {
      let user = this.$store.state.users.find(user => user.id == this.user.code);
      this.licenseNumber = user.license_number;
    }
  },
  methods: {
    getLocations() {
      Api.get('locations').then(response => {
        this.locations = response;
      });
    },
    getDates() {
      Api.get('schedule/dates?location='+this.location).then(response => {
        this.dates = response;
      });
    },
    getTimes() {
      Api.get('schedule/times?date='+this.date+'&location='+this.location).then(response => {
        this.times = response;
      });
    },
    reserveAppointment() {
      if (!this.$refs.form.checkValidity()) {
				this.$refs.form.reportValidity();
        return false;
      }
      let user = this.$store.state.userData.id;
      if (this.$store.state.userData.role == 'organization') {
        user = this.user.code;
      }
      let appointment = { appointmentId: this.time, date: this.date, time: this.times[this.time], licenseNumber: this.licenseNumber, classType: this.classType, user: user };
      this.$store.commit('addAppointmentToIncompleteCartItem', appointment);
      this.$store.commit('changeOrderStep', 'cart');
    },
    skipAppointment() {
      this.$store.commit('addVoucherToIncompleteCartItem');
      this.$store.commit('changeOrderStep', 'cart');
    },
    buildUserOptions() {
      if (this.$store.state.users.length > 0) {
        var userOptions = [];
        this.$store.state.users.forEach(user => {
          userOptions.push({
            label: user.first_name + ' ' + user.last_name,
            code: user.id
          });
        });
        this.userOptions = userOptions;
      }
    },
    convertTime(time) {
      return moment(time, 'HH:mm:ss').format("h:mma");
    }
  },
  created() {
    this.getLocations();
    this.buildUserOptions();
  }
}
</script>

<style lang="scss" scoped>

</style>