<template>
  <div class="address-item user-item">
    <h5>{{ user.first_name }} {{ user.last_name }}</h5>
    <div class="user text-1">
      
    </div>
    <div class="phone text-1" v-if="user.phone != null">
      Phone: {{ user.phone }}
    </div>
    <div class="email text-1" v-if="user.email != null">
      Email: {{ user.email }}
    </div>
    <div class="actions">
      <a class="text-0" @click="editUser(user)">Edit</a> | <a class="text-0" @click="deleteUser(user.id)">Delete</a>
    </div>
    <div class="popup edit-user" v-if="editingUser != null">
      <a class="close" @click="close"><i class="far fa-times"></i></a>
      <h4>Edit Your User</h4>
      <form class="form" ref="editUserForm" v-on:submit.prevent="updateUser">
        <div class="form-item half">
          <input type="text" v-model="editingUser.first_name" placeholder="First Name" required />
        </div>
        <div class="form-item half">
          <input type="text" v-model="editingUser.last_name" placeholder="Last Name" required />
        </div>
        <div class="form-item">
          <input type="text" v-model="editingUser.email" placeholder="Email" required />
        </div>
        <div class="form-item">
          <input type="text" placeholder="Street Address" v-model="editingUser.address1" required />
        </div>
        <div class="form-item half">
          <input type="text" placeholder="City" v-model="editingUser.city" required />
        </div>
        <div class="form-item half">
          <select v-model="editingUser.state" required>
            <option value="">Choose State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="GU">Guam</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="PR">Puerto Rico</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VI">Virgin Islands</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div class="form-item half">
          <input type="text" placeholder="5 Digit Zipcode (xxxxx)" v-model="editingUser.zipcode" required :maxlength="5" />
        </div>
        <div class="form-item half">
          <input type="text" placeholder="Phone" v-model="editingUser.phone" required />
        </div>
        <div class="form-item">
          <input type="text" placeholder="License/Permit #" v-model="editingUser.license_number" required />
        </div>
        <div class="form-item full">
          <button type="submit" class="button">Save User</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'

export default {
  name: 'User',
  props: [
    'user'
  ],
  data() {
    return {
      editingUser: null
    };
  },
  methods: {
    editUser(user) {
      this.editingUser = user;
    },
    deleteUser(userId) {
      this.$confirm({
        message: `Are you sure you want to delete this user?`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            Api.deleteAuth('account/user/'+userId, this.$store.state.userData.token).then(response => {
              console.log(response);
              this.$emit('user-updated');
            });
          }
        }
      });
    },
    updateUser() {
      // Make sure form is validate before proceeding
			if (!this.$refs.editUserForm.checkValidity()) {
				this.$refs.editUserForm.reportValidity();
        return false;
      }
      this.$overlay(true, 'Saving user changes.');
      Api.putAuth('account/user/'+this.editingUser.id, this.editingUser, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$emit('user-updated');
          this.$overlay(false);
          this.close();
        }
      });
    },
    close() {
      this.editingUser = null;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>