<template>
  <div class="reschedule popup">
    <a class="close" @click="close"><i class="far fa-times"></i></a>
    <form class="form" ref="form" v-on:submit.prevent="reserveAppointment">
      <div class="table-row flex-column w-100 full-test mb-3">
        <h3 class="text-center">Rechedule Appointment</h3>

        <div class="form-item text-center mb-3">
          <p class="mb-0"><strong>Currently Scheduled For</strong></p>
          <p>{{ formattedDatetime }} at {{ appointment.location.name }}</p>
        </div>

        <div class="form-item text-center py-2">
          <label>Choose A Testing Location</label>
          <select v-model="location" required>
            <option :value="location['ID']" v-for="location in locations" :key="location">{{ location.post_title }}</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="location != null && dates != null && dates != false">
          <label>Choose A Day</label>
          <div class="d-block">
            <v-date-picker
            v-model="date"
            :selectedDate.sync="appointment.datetime"
            mode='date'
            :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            }"
            :available-dates="dates"
            />
          </div>
        </div>

        <div class="form-item text-center py-2" v-if="dates == false">
          <p class="font-italic text-center">Sorry, we have no available appointment dates for this location. Please check back soon.</p>
        </div>

        <div class="form-item text-center py-2" v-if="date != null">
          <label>Choose The Time</label>
          <select v-model="time" required>
            <option :value="key" v-for="(time, key) in times" :key="time">{{ convertTime(time) }}</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="time != null">
          <label>Class A or Class B</label>
          <select v-model="classType" required>
            <option value="">Select an option</option>
            <option value="Class A">Class A</option>
            <option value="Class B">Class B</option>
          </select>
        </div>

        <div class="form-item text-center py-2" v-if="time != null">
          <label>License/Permit #</label>
          <input type="text" v-model="licenseNumber" required min="5" />
        </div>

        <div class="form-item text-center d-flex flex-column" v-if="time != null && licenseNumber != null && classType != null">
          <input type="submit" class="button" value="Reschedule Appointment" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import moment from 'moment/moment.js'

export default {
  name: 'Reschedule',
  props: [
    'appointment'
  ],
  components: {
    
  },
  computed: {
    formattedDatetime() {
      return moment(this.appointment.datetime).format("MMM Do YYYY h:mm:ssa");
    }
  },
  data() {
    return {
      location: null,
      date: null,
      time: null,
      locations: null,
      dates: null,
      times: null,
      licenseNumber: null,
      classType: null,
      submitting: false
    };
  },
  watch: {
    location() {
      this.getDates();
      this.date = null;
      this.time = null;
    },
    date() {
      this.getTimes();
      this.time = null;
    }
  },
  methods: {
    getLocations() {
      Api.get('locations').then(response => {
        this.locations = response;
      });
    },
    getDates() {
      Api.get('schedule/dates?location='+this.location).then(response => {
        this.dates = response;
      });
    },
    getTimes() {
      Api.get('schedule/times?date='+this.date+'&location='+this.location).then(response => {
        this.times = response;
      });
    },
    reserveAppointment() {
      if (!this.$refs.form.checkValidity()) {
				this.$refs.form.reportValidity();
        return false;
      }
      this.$overlay(true, 'Rescheduling your appointment.');
      let appointment = { appointmentId: this.time, date: this.date, time: this.times[this.time], licenseNumber: this.licenseNumber, classType: this.classType, orderId: this.appointment.orderId, rental: this.appointment.rental, user: this.appointment.user.id };
      Api.putAuth('schedule/reschedule-appointment/'+this.appointment.id, appointment, this.$store.state.userData.token).then(response => {
        console.log(response);
        if (!response.success == false) {
          this.$overlay(true, 'There was an error rescheduling. Please contact us to finish.', 3000);
        }
        
        this.submitting = false;
        this.$overlay(true, 'The appointment was rescheduled.', 3000);
        this.$emit('close');
      });
      return false;
    },
    close() {
      this.$emit('close');
    },
    setFields() {
      this.location = this.appointment.location.id;
      this.classType = this.appointment.classType;
      this.licenseNumber = this.appointment.licenseNumber;
    },
    convertTime(time) {
      return moment(time, 'HH:mm:ss').format("h:mma");
    }
  },
  mounted() {
    this.getLocations();
    this.setFields();
  }
}
</script>

<style lang="scss" scoped>

</style>