<template>
  <div id="appointments">
    <div class="appointment mb-3 box-shadow p-3" v-for="appointment in appointments" :key="appointment.id">
      <h5 class="my-2 primary">{{ appointment.user.first_name }} {{ appointment.user.last_name }}</h5>
      <div class="meta d-flex flex-column">
        <div class="meta-item">
          {{ formatDate(appointment.datetime) }}
        </div>
        <div class="meta-item">
          {{ appointment.location.name }}
        </div>
      </div>
      <div class="actions" v-if="isReschedulable(appointment.datetime)">
        <a class="mr-3" @click="setReschedule(appointment)">Reschedule</a>
        <a @click="setSwap(appointment)">Swap With Another Appointment</a>
      </div>
    </div>
    <Reschedule :appointment="rescheduleAppointment" v-if="rescheduleAppointment != null" v-on:close="unsetReschedule()"></Reschedule>
    <Swap :appointment="swapAppointment" :appointments="appointments" v-if="swapAppointment != null" v-on:close="unsetSwap()"></Swap>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import moment from 'moment/moment.js'
import Reschedule from '@/components/Reschedule';
import Swap from '@/components/Swap';

export default {
  name: 'Appointments',
  components: {
    Reschedule,
    Swap
  },
  props: [
    'userId'
  ],
  data() {
    return {
      appointments: null,
      rescheduleAppointment: null,
      swapAppointment: null
    };
  },
  methods: {
    getAppointments() {
      Api.getAuth('account/user-appointments/'+this.$store.state.userData.id, this.$store.state.userData.token).then(response => {
        this.appointments = response;
      });
    },
    formatDate(date) {
      return moment(date).format("MMM Do YYYY h:mm:ssa");
    },
    setReschedule(appointmentId) {
      this.rescheduleAppointment = appointmentId;
    },
    unsetReschedule() {
      this.setReschedule(null);
      this.getAppointments();
    },
    setSwap(appointmentId) {
      this.swapAppointment = appointmentId;
    },
    unsetSwap() {
      this.setSwap(null);
      this.getAppointments();
    },
    isReschedulable(datetime) {
      if (Date.parse(datetime) > new Date(Date.now() + ( 3600 * 1000 * 48))) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getAppointments();
  }
}
</script>

<style lang="scss" scoped>

</style>