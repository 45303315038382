<template>
  <section id="scheduler" class="small-section">
    <vue-confirm-dialog></vue-confirm-dialog>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <section id="nav" class="filter-bar px-4 red-links mb-4">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-lg-6 col-xl-6 text-1 mb-3 mb-lg-0">
                <a class="d-block d-lg-inline-block text-center" @click="$store.commit('changeOrderStep', 'products')">Schedule A Test</a>
              </div>
              <div class="col-12 col-lg-6 col-xl-6 text-center text-lg-right d-flex align-items-center justify-content-end">
                <a @click="$store.commit('changeOrderStep', 'cart')"><i class="far fa-shopping-cart"></i></a>
                <div class="p-2 border text-center" v-if="$store.state.userData != null">
                  <span class="text-1 d-lg-block">{{ $store.state.userData.displayName }}</span> <a class="text-0" @click="$store.commit('changeOrderStep', 'view-account')">My Account</a> <span class="d-none d-lg-inline">|</span> <a  class="text-0" @click="$store.commit('logout')">Logout</a>
                </div>
                <div class="" v-else>
                  <a class="button" @click="$store.commit('changeOrderStep', 'account')">Login / Create Account</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <section id="products" v-if="$store.state.orderStep == 'products'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-12">
            <Products></Products>
          </div>
        </div>
      </div>
    </section>

    <section id="rentals" v-if="$store.state.orderStep == 'rentals'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 mx-auto">
            <Rentals></Rentals>
          </div>
        </div>
      </div>
    </section>

    <section id="rentals" v-if="$store.state.orderStep == 'entry-level-drivers-training'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-10 mx-auto">
            <section class="voucher box-shadow mb-3">
              <div class="box-header">Verify Entry-Level Driver's Training Before Proceeding</div>
              <div class="content white-bg py-3 px-4">
                <div class="form">
                  <div class="form-item text-center py-2 d-flex justify-content-center align-items-center">
                    <label class="m-0" style="font-weight: 500; font-size: 1.25rem; margin-right: 1rem !important; text-transform: capitalize;">Check the box to confirm you have completed <strong><a href="https://www.fmcsa.dot.gov/registration/commercial-drivers-license/entry-level-driver-training-eldt" target="_blank">Entry Level Driver's Training</a></strong>.</label>
                    <input type="checkbox" @click="$store.commit('changeOrderStep', 'rentals')" />
                  </div>
                  <div class="description text-1 text-center">Students who have not completed this training cannot schedule a test. To verify your results, visit <a href="https://www.fmcsa.dot.gov/registration/commercial-drivers-license/entry-level-driver-training-eldt" target="_blank">https://www.fmcsa.dot.gov/registration/commercial-drivers-license/entry-level-driver-training-eldt</a></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <section id="schedule" v-if="$store.state.orderStep == 'schedule'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-6 mx-auto">
            <Schedule></Schedule>
          </div>
        </div>
      </div>
    </section>

    <section id="cart" v-if="$store.state.orderStep == 'cart'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 mx-auto">
            <Cart></Cart>
          </div>
        </div>
      </div>
    </section>

    <section id="view-account" v-if="$store.state.orderStep == 'view-account'">
      <ViewAccount></ViewAccount>
    </section>

    <section id="account" v-if="$store.state.orderStep == 'account'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <Account></Account>
          </div>
        </div>
      </div>
    </section>

    <section id="billing" v-if="$store.state.orderStep == 'billing'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 col-xl-9 mb-3 mb-lg-0">
            <Billing></Billing>
          </div>
          <div class="col-12 col-lg-4 col-xl-3">
            <Cart></Cart>
          </div>
        </div>
      </div>
    </section>

    <section id="review" v-if="$store.state.orderStep == 'review'">
      <ReviewOrder></ReviewOrder>
    </section>

    <section id="confirmation" v-if="$store.state.orderStep == 'confirmation'">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-8 mx-auto">
            <section id="shipping-review" class="box-shadow red-links mb-3">
              <div class="box-header mb-0">Your Order Has Been Placed</div>
              <div class="content p-4 white-bg">
                <p class="mb-0 text-center">Thank you for your order. See additional details below.</p>
                <div class="mt-3" v-if="$store.state.completeOrder != null">
                  <div v-if="$store.state.completeOrder.appointments != null">
                    <div class="gray-bg p-3 mb-3">
                      <h3 class="text-center">Your Appointments</h3>
                      <div class="appointment text-center my-2 white-bg" style="border: 1px solid #ccc; padding: 1rem;" v-for="appointment in $store.state.completeOrder.appointments" :key="appointment">
                        <p class="mb-1"><strong>{{ appointment.location }}</strong></p>
                        <p class="m-0">{{ formatDateTime(appointment.startDate) }}</p>
                      </div>
                    </div>
                    <div class="notice">
                      <ul>
                        <li>All presented permits must be legible, sealed, legal and valid. No person will be permitted to test with problematic or unclear documentation.</li>
                        <li>Must bring license AND permit to be able to test.</li>
                        <li>Vehicles must have a manufactured VIN plate that clearly shows the Gross Vehicle Weight Rating (GVWR). Registrations will not work, paperwork from the manufacturer with GVWR is the only other proof that is permitted.</li>
                        <li>Persons not having proper documentation will have to reschedule and pay another fee.</li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="$store.state.completeOrder.vouchers != null">
                    <h3 class="text-center">Your Vouchers</h3>
                    <div class="voucher text-center" v-for="voucher in $store.state.completeOrder.vouchers" :key="voucher">
                      <p>{{ voucher.product.post_title }} - <strong>{{ voucher.code }}</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Products from '@/components/Products'
import Rentals from '@/components/Rentals'
import Schedule from '@/components/Schedule'
import Cart from '@/components/Cart'
import Account from '@/components/Account'
import Billing from '@/components/Billing'
import ViewAccount from '@/components/ViewAccount'
import ReviewOrder from '@/components/ReviewOrder'
import Cookies from 'js-cookie'
import Api from '@/models/Api.js'

export default {
  name: 'Scheduler',
  components: {
    Products,
    Rentals,
    Schedule,
    Cart,
    Account,
    Billing,
    ViewAccount,
    ReviewOrder
  },
  data() {
    return {
      
    };
  },
  methods: {
    prevStep() {
      this.$store.commit('prevStep');
    },
    formatDateTime(dateTime) {
      let timestamp = Date.parse(dateTime);
      let date = new Date(timestamp);
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      let year = date.getFullYear();
      let month = months[date.getMonth()];
      let day = date.getDate();
      let time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      return month+' '+day+', '+year+' at '+time;
    },
    validateToken() {
      if (this.$store.state.userData != null) {
        Api.postAuthFullResponse('validate-token', [], this.$store.state.userData.token).then(response => {
          console.log(response);
          if (response.data.statusCode == 403 || response.status == 403) {
            console.log('Invalid Token');
            this.$store.commit('logout');
          } else {
            console.log('Valid Token');
          }
          return true;
        }).catch(() => {
          console.log('Invalid Token');
          this.$store.commit('logout');
        });
      }
    }
  },
  mounted() {
    if (typeof Cookies.get('userData') != 'undefined') {
      this.$store.commit('setUserData', JSON.parse(Cookies.get('userData')));

      if (this.$store.state.userData.role == 'organization') {
        this.$store.commit('getUserUsers');
      }
    }

    this.validateToken();

    window.onpopstate = (event) => { console.log(event); this.prevStep(); return false; };

    this.$store.commit('setOrderUrl', window.location.href);
  },
  created() {
    let hash = location.hash.replace("#", "");
    if (hash == 'account') {
      this.$store.commit('changeOrderStep', 'account');
    }
  }
}
</script>
