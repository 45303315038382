<template>
  <div id="view-account" class="red-links">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-3">
          <div class="box-header">My Account</div>
          <nav class="white-bg box-shadow p-3 d-flex flex-column">
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('profile')">Profile</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('billingAddresses')">Manage Billing Addresses</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('orders')" v-if="1 == 2">View Orders</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('users')" v-if="$store.state.userData.role == 'organization'">Manage Users</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('schedule')" v-if="$store.state.userData.role == 'organization'">Schedule</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="setShow('vouchers')" v-if="$store.state.userData.role == 'organization'">Vouchers</a>
            <a class="text-0 font-weight-bold text-uppercase mb-2" style="cursor: pointer;" @click="$store.commit('changeOrderStep', 'products')">Back To Tests</a>
          </nav>
        </div>
        <div class="col-12 col-lg-9">
          <section class="account-panel" v-if="show == 'profile'">
            <div class="box-header">Profile</div>
            <div class="content white-bg box-shadow p-3">
              <form class="form" v-on:submit.prevent="updateAccount">
                <div class="form-item">
                  <input type="text" v-model="userData.displayName" placeholder="Company" />
                </div>
                <div class="form-item half">
                  <input type="text" v-model="userData.firstName" placeholder="First Name" />
                </div>
                <div class="form-item half">
                  <input type="text" v-model="userData.lastName" placeholder="Last Name" />
                </div>
                <div class="form-item">
                  <button type="submit" class="button">Save Changes</button>
                </div>
              </form>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'billingAddresses'">
            <div class="box-header">Billing Addresses</div>
            <div class="content white-bg box-shadow p-3 addresses" v-if="$store.state.billingAddresses.length > 0">
              <Address v-for="address in $store.state.billingAddresses" v-bind:key="address.id" :address="address" v-on:address-updated="$store.commit('getUserAddresses')"></Address>
              <div class="w-100 text-center mt-2"><a class="button mb-3" @click="showNewAddress('billing')">Add New Address</a></div>
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no saved addresses.</h5>
              <a class="button mb-3" @click="showNewAddress('billing')">Add New Address</a>
            </div>
          </section>

          <div class="popup new-address" v-if="show == 'newAddress'">
            <a class="close" @click="closeNewAddress"><i class="far fa-times"></i></a>
            <h4>Add New Address</h4>
            <form class="form" v-on:submit.prevent="saveNewAddress">
              <div class="form">
                <!-- <div class="form-item">
                  <select v-model="newAddress.country" required>
                    <option value="">Choose Country</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                  </select>
                </div> -->
                <div class="form-item half">
                  <input type="text" placeholder="Name" v-model="newAddress.name" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Company" v-model="newAddress.company" />
                </div>
                <div class="form-item">
                  <input type="text" placeholder="Street Address" v-model="newAddress.address1" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="City" v-model="newAddress.city" />
                </div>
                <div class="form-item half" v-if="newAddress.country == 'US'">
                  <select v-model="newAddress.state" required>
                    <option value="">Choose State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="GU">Guam</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VI">Virgin Islands</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div class="form-item half" v-if="newAddress.country == 'CA'">
                  <select v-model="newAddress.state" required>
                    <option value="">Choose Province</option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </select>
                </div>
                <div class="form-item half">
                  <input type="text" :placeholder="newAddress.country == 'CA' ? '6 Digit Postal Code (xxxxxx)' : '5 Digit Zipcode (xxxxx)'" v-model="newAddress.zipcode" required :maxlength="newAddress.country == 'CA' ? 6 : 5" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Phone" v-model="newAddress.phone" required />
                </div>
                <div class="form-item full">
                  <button type="submit" class="button">Save Address</button>
                </div>
              </div>
            </form>
          </div>

          <section class="account-panel" v-if="show == 'orders'">
            <div class="box-header">Orders</div>
            <div class="content white-bg box-shadow p-3 orders" v-if="orders.length != null">
              <div class="table-header w-100">
                <div class="col id">Order ID</div>
                <div class="col">Date</div>
              </div>
              <div class="table-row d-flex flex-column" v-for="order in orders" v-bind:key="order.id">
                <div class="top w-100">
                  <div class="col id"><a @click="expandOrder(order.id)">{{ order.id }}</a></div>
                  <div class="col">{{ order.date_created }}</div>
                </div>
                <div class="bottom w-100" v-if="expandedOrder == order.id">
                  <div class="col">
                    <h4>Payment Method</h4>
                    <p class="">{{ order.payment_method }}</p>

                    <h4>Total Price</h4>
                    <p class="">${{ order.total_price }}</p>
                  </div>
                  <div class="col">

                  </div>
                </div>
              </div>
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no previous orders.</h5>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'users'">
            <div class="box-header">Users</div>
            <div class="content white-bg box-shadow p-3 users" v-if="$store.state.users.length != 0">
              <User v-for="user in $store.state.users" v-bind:key="user.id" :user="user" v-on:user-updated="$store.commit('getUserUsers')"></User>
              <div class="w-100 text-center mt-2"><a class="button mb-3" @click="showNewUser()">Add New User</a></div>
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no saved users.</h5>
              <a class="button mb-3" @click="showNewUser()">Add New User</a>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'schedule'">
            <div class="box-header">Schedule</div>
            <div class="content white-bg box-shadow p-3" v-if="$store.state.users.length != 0">
              <Appointments :userId="$store.state.userData.id"></Appointments>           
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no saved users.</h5>
              <a class="button mb-3" @click="showNewUser()">Add New User</a>
            </div>
          </section>

          <div class="popup new-address" v-if="show == 'newUser'">
            <a class="close" @click="closeNewUser"><i class="far fa-times"></i></a>
            <h4>Add New User</h4>
            <form class="form" v-on:submit.prevent="saveNewUser">
              <div class="form">
                <div class="form-item half">
                  <input type="text" v-model="newUser.first_name" placeholder="First Name" required />
                </div>
                <div class="form-item half">
                  <input type="text" v-model="newUser.last_name" placeholder="Last Name" required />
                </div>
                <div class="form-item">
                  <input type="text" v-model="newUser.email" placeholder="Email" required />
                </div>
                <div class="form-item">
                  <input type="text" placeholder="Street Address" v-model="newUser.address1" required />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="City" v-model="newUser.city" required />
                </div>
                <div class="form-item half">
                  <select required v-model="newUser.state">
                    <option value="">Choose State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="GU">Guam</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VI">Virgin Islands</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="5 Digit Zipcode (xxxxx)" v-model="newUser.zipcode" required :maxlength="5" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Phone" v-model="newUser.phone" required />
                </div>
                <div class="form-item">
                  <input type="text" placeholder="License/Permit #" v-model="newUser.license_number" required />
                </div>
                <div class="form-item full">
                  <button type="submit" class="button">Save User</button>
                </div>
              </div>
            </form>
          </div>

          <section class="account-panel" v-if="show == 'vouchers'">
            <div class="box-header">Vouchers</div>
            <div class="content white-bg box-shadow p-3 orders" v-if="vouchers.length != null">
              <div class="table-header w-100">
                <div class="col">Product</div>
                <div class="col">Voucher</div>
              </div>
              <div class="table-row d-flex flex-column" v-for="voucher in vouchers" v-bind:key="voucher.id">
                <div class="top w-100">
                  <div class="col">{{ voucher.product.post_title }}</div>
                  <div class="col">{{ voucher.code }}</div>
                </div>
              </div>
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no available vouchers.</h5>
            </div>
          </section>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import Address from '@/components/Address.vue'
import User from '@/components/User.vue'
import Appointments from '@/components/Appointments.vue'

export default {
  name: 'ViewAccount',
  components: {
    Address: Address,
    User: User,
    Appointments: Appointments
  },
  data() {
    return {
      show: 'profile',
      newAddress: {
        customer_id: this.$store.state.userData.id,
        type: '',
        country: 'US',
        name: '',
        company: '',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        phone: ''
      },
      newUser: {
        customer_id: this.$store.state.userData.id,
        first_name: '',
        last_name: '',
        email: '',
        country: 'US',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        phone: ''
      },
      userData: {
        displayName: '',
        firstName: '',
        lastName: ''
      },
      orders: null,
      expandedOrder: null,
      vouchers: null
    };
  },
  methods: {
    setShow(show) {
      this.show = show;
    },
    showNewAddress(type) {
      this.newAddress.type = type;
      this.setShow('newAddress');
    },
    showNewUser() {
      this.setShow('newUser');
    },
    saveNewAddress() {
      Api.postAuth('account/address', this.newAddress, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$store.commit('getUserAddresses');
          this.closeNewAddress();
          this.$overlay(true, "Your address was successfully added.", 2000);
        }
      });
    },
    saveNewUser() {
      Api.postAuth('account/user', this.newUser, this.$store.state.userData.token).then(response => {
        if (response.hasOwnProperty('message')) {
          this.$overlay(true, response.message, 2000);
          return false;
        }
        if (response) {
          this.$store.commit('getUserUsers');
          this.closeNewUser();
          this.$overlay(true, "The user was successfully added.", 2000);
        }
      });
    },
    closeNewAddress() {
      this.setShow(this.newAddress.type+'Addresses');
    },
    closeNewUser() {
      this.setShow('users');
    },
    updateAccount() {
      Api.putAuth('account/'+this.$store.state.userData.id, this.userData, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$overlay(true, "Your account was successfully updated.", 2000);
        }
      });
    },
    getUserData() {
      Api.getAuth('account/'+this.$store.state.userData.id, this.$store.state.userData.token).then(response => {
        this.userData = response;
      });
    },
    getOrders() {
      Api.getAuth('account/'+this.$store.state.userData.id+'/orders', this.$store.state.userData.token).then(response => {
        this.orders = response;
      });
    },
    expandOrder(id) {
      this.expandedOrder = id;
    },
    getVouchers() {
      Api.getAuth('account/'+this.$store.state.userData.id+'/vouchers', this.$store.state.userData.token).then(response => {
        this.vouchers = response;
      });
    }
  },
  mounted() {
    this.$store.commit('getUserAddresses');
    this.$store.commit('getUserUsers');
    this.getUserData();
    this.getOrders();
    this.getVouchers();
  }
}
</script>

<style lang="scss" scoped>

</style>