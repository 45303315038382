<template>
  <div id="account">
    <section id="account-chooser" class="box-shadow mx-0" v-if="show == 'account-chooser'">
      <div class="box-header">Login/Register</div>
      <div class="content white-bg p-4 d-flex align-items-center flex-column flex-lg-row">
        <div class="w-100 w-lg-50 text-center mb-3 mb-lg-0">
          <h4>Have an account already?</h4>
          <button class="button red-bg login" @click="setShow('login')">Login</button>
        </div>
        <div class="line" style="width: 1px; height: 60%; background: #ccc;"></div>
        <div class="w-100 w-lg-50 text-center">
          <h4>Don't have an account?</h4>
          <button class="button red-bg register" @click="setShow('register')">Create Account</button>
        </div>
      </div>
    </section>
    <section id="login" class="box-shadow mx-0 mx-lg-5 red-links" v-if="show == 'login'">
      <div class="box-header">Login</div>
      <div class="content white-bg p-4 d-flex align-items-center justify-content-center">
        <form class="form" @submit.prevent="login()">
          <div class="form-item half">
            <label>Email</label>
            <input class="email" type="text" v-model="username" placeholder="Email" />
          </div>
          <div class="form-item half">
            <label>Password</label>
            <input class="password" type="password" v-model="password" placeholder="Password" />
            <div class="description mt-1">Can't remember your password? <a href="/reset-password/" target="_blank" class="font-weight-bold">Click here to reset your password.</a></div>
          </div>
          <div class="form-item half">
            <button class="button login" type="submit">Log In</button>
          </div>
          <div class="form-item text-center mt-3 font-weight-bold"><a @click="setShow('register')">Don't have an account? Register now.</a></div>
        </form>
      </div>
    </section>
    <section id="register-account" class="box-shadow mx-0 mx-lg-5 red-links" v-if="show == 'register'">
      <div class="box-header">Register Account</div>
      <div class="content white-bg p-4 d-flex align-items-center">
        <form ref="registerform" class="form" @submit.prevent="register()">
          <h4 class="form-item ml-1">Account Type</h4>
          <div class="form-item">
            <select v-model="newAccount.customerType" required>
              <option value="">Are you an individual or an organization?</option>
              <option value="customer">Individual/Test Taker</option>
              <option value="organization">Organization</option>
            </select>
          </div>

          <h4 class="form-item ml-1">Contact Info</h4>
          <div class="form-item">
            <input type="text" v-model="newAccount.company" placeholder="Company" required />
          </div>
          <div class="form-item half">
            <input type="text" v-model="newAccount.firstName" placeholder="First Name" required />
          </div>
          <div class="form-item half">
            <input type="text" v-model="newAccount.lastName" placeholder="Last Name" required />
          </div>
          <div class="form-item half">
            <input type="text" v-model="newAccount.email" placeholder="Email" required />
          </div>
          <div class="form-item half">
            <input type="text" minlength="14" v-model="newAccount.phone" placeholder="Phone" required />
          </div>

          <h4 class="form-item mt-2 ml-1">Password</h4>
          <div class="form-item half">
            <input type="password" v-model="newAccount.password" placeholder="Password" minlength="6" required />
            <div class="description">Your password must be at least 6 characters long, have at least one uppercase and one lowercase letter, and have at least one number.</div>
          </div>
          <div class="form-item half">
            <input type="password" v-model="newAccount.passwordConfirm" placeholder="Password Confirm" minlength="6" required />
          </div>
          <div class="form-item mt-2">
            <button class="button" type="submit" v-if="canCreateAccount && submitting == false">Create Account</button>
          </div>
          <div class="form-item text-center mt-3 font-weight-bold"><a @click="setShow('login')">Already have an account? Click here to login.</a></div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import { formatPhone } from '@/helpers.js'

export default {
  name: 'Account',
  data() {
    return {
      username: '',
      password: '',
      newAccount: {
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        customerType: '',
        // address: {
        //   address1: '',
        //   city: '',
        //   state: '',
        //   zipcode: '',
        //   country: ''
        // }
      },
      show: 'account-chooser',
      submitting: false
    };
  },
  computed: {
    canCreateAccount() {
      if (this.existingCustomer == 'yes' && this.hasNavId == 'no') {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    'newAccount.phone': function(newVal) {
      if (newVal != null) {
        this.newAccount.phone = formatPhone(newVal);
      }
    }
  },
  methods: {
    login() {
      return Api.login(this.username, this.password).then(response => {
        if (response.success == true) {
          Api.getAuth('account/'+response.data.id+'?extra=1', response.data.token).then(accountResponse => {
            if (!accountResponse.hasOwnProperty('message')) {
              let userData = { ...response.data, ...accountResponse };
              this.$store.commit('setUserData', userData);
              if (this.$store.state.cartItems.length > 0) {
                this.$store.commit('changeOrderStep', 'billing');
              } else {
                this.$store.commit('changeOrderStep', 'products');
              }
              this.$overlay(true, 'Please wait while we load our available tests.', 2000);
              return response.data;
            } else {
              this.$overlay(true, '<span class="text-0 red-links">'+accountResponse.message+'</span>', 3000);
            }
          });
        } else {
          this.$overlay(true, response.message, 3000);
        }
      });
    },
    register() {
      // Make sure form is validate before proceeding
			if (!this.$refs.registerform.checkValidity()) {
				this.$refs.registerform.reportValidity();
        return false;
      }

      if (this.newAccount.password != this.newAccount.passwordConfirm) {
        this.$overlay(true, 'Your password and confirmation password do not match.', 2000);
        return false;
      }

      if (this.newAccount.password.length < 6) {
        this.$overlay(true, 'Your password is not long enough.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/\d/) == -1) {
        this.$overlay(true, 'Your password does not contain a number.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[A-Z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an uppercase letter.', 2000);
        return false;
      }

      if (this.newAccount.password.search(/[a-z]/) == -1) {
        this.$overlay(true, 'Your password does not contain an lowercase letter.', 2000);
        return false;
      }

      this.submitting = true;

      Api.post('account', this.newAccount).then(response => {
        if (response.success == true) {
          this.username = this.newAccount.email;
          this.password = this.newAccount.password;
          this.login();
          // this.login().then(response => {
          //   Api.postAuth('account/address', { ...this.newAccount.address, type: 'billing', customer_id: response.id, company: this.newAccount.company, name: this.newAccount.firstName+' '+this.newAccount.lastName }, this.$store.state.userData.token).then(() => {
          //     this.$overlay(true, 'Your account was successfully created.', 3000);
          //   });
          // });
        } else {
          this.submitting = false;
          let message = '';
          Object.keys(response.message).forEach(key => {
            message += response.message[key];
          });
          this.$overlay(true, message, 2000);
        }
      });
    },
    setShow(newShow) {
      this.show = newShow;
    }
  },
  mounted() {
    if (this.$store.state.userData != null) {
      this.$store.commit('changeOrderStep', 'billing');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>