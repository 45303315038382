import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Scheduler from './Scheduler.vue'
import VueOverlay from '@/components/plugins/overlay'
import VueConfirmDialog from 'vue-confirm-dialog'
import LoadScript from 'vue-plugin-load-script';
import VCalendar from 'v-calendar';
import store from './store'
import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(LoadScript);

Vue.loadScript("https://js.stripe.com/v3/").then(() => {
  const stripeOptions = {
    pk: 'pk_test_UOP63FRwe8AlkaJwGY8Je7xP',//process.env.STRIPE_PUBLISHABLE_KEY,
    stripeAccount: 'ideasandpixels'//process.env.STRIPE_ACCOUNT,
    // apiVersion: process.env.API_VERSION,
    // locale: process.env.LOCALE,
  };
  Vue.use(StripePlugin, stripeOptions);
});

Vue.use(VueOverlay);
Vue.use(VueConfirmDialog);
Vue.use(VCalendar);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false

if (document.querySelector("#scheduler")) {
  new Vue({
    store,
    render: h => h(Scheduler),
  }).$mount('#scheduler');
}