<template>
  <div id="rentals">
    <div class="form">
      <div class="table-row py-4 px-3 flex-column w-100 full-test mb-3" v-if="rental == false">
        <h3 class="text-center text-uppercase mb-3">Vehicle Rental</h3>
        <div class="form-item text-center py-2">
          <label>Do you need to rent a vehicle for your test?</label>
          <p class="description">We only have standard shift trucks available for rental.</p>
          <!-- <vue-toggle-btn @setIsActive="setRental($event)"></vue-toggle-btn> -->
          <div class="form-item d-flex justify-content-center">
            <a class="button mx-1" @click="setRental(true)">I Need A Rental</a>
            <a class="button mx-1" @click="$store.commit('changeOrderStep', 'schedule');">I Do Not Need A Rental</a>
          </div>
        </div>
      </div>
      <div class="table-row py-4 px-3 flex-column w-100 full-test mb-3" v-if="rental == true">
        <div class="form-item py-2">
          <label>Which type of vehicle would you like to rent?</label>
          <select v-model="vehicle">
            <option :value="index" v-for="vehicleOption, index in vehicleOptions" :key="index">{{ vehicleOption.label }}</option>
          </select>
        </div>
        <div class="form-item d-flex justify-content-center">
          <a class="button mx-2" @click="addRental()" v-if="rental == true && vehicle != null">Add To Order</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VueToggleBtn from 'vue-toggle-btn/toggle-btn.vue'

export default {
  name: '',
  components: {
    //'vue-toggle-btn': VueToggleBtn
  },
  data() {
    return {
      rental: false,
      vehicle: null,
      vehicleOptions: [
        {
          label: '($200) Class A Truck & Trailer',
          price: '200.00'
        },
        {
          label: '($160) Class B Truck',
          price: '160.00'
        },
        {
          label: '($160) Class B Bus',
          price: '160.00'
        }
      ]
    };
  },
  methods: {
    setRental($event) {
      this.rental = $event;
    },
    addRental() {
      let cartItem = {
        post_name: "rental",
        post_title: "Rental",
        post_type: "rental",
        price: this.vehicleOptions[this.vehicle].price,
        type: "Rental",
        vehicle: this.vehicleOptions[this.vehicle].label,
        qty: 1
      };
      this.$store.commit('addToCart', cartItem);
      this.$store.commit('changeOrderStep', 'schedule');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>